import React from 'react'
import { Link } from 'react-router-dom'
import {
  Container,
  SectionOne,
  LogoOxeDin,
  LogoOxeTechLab,
  LogoOxeTechWork,
  LogoOxeTechHat,
  TriangleFrame,
  InovationMap,
  Text
} from './styles'

import Typography from '@mui/material/Typography'

import MainHeader from '../../components/MainHeader'

import triangleFrame from '../../assets/homeTriangle/triangleFrameG.png'

import oxeTechHat from '../../assets/homeTriangle/oxeTechHat.png'

import oxeDinLogo from '../../assets/homeTriangle/oxeDinLogo.png'

import oxeTechLabLogoON from '../../assets/homeTriangle/oxeTechLabLogoON.png'

import oxeTechWorkLogo from '../../assets/homeTriangle/oxeTechWorkLogo.png'

import InovationMapIcon from '../../assets/homeTriangle/InovationMapIcon.png'
const divStyle = {
  display: 'flex',
  alignItems: 'center',
  border: '1px',
  width: '100%',
  float: 'left',
  height: '500px',
  position: 'relative',
  margin: 'auto'
}

// TODO Stack Menus OxeTech

function Main(Props) {
  return (
    <>
      <MainHeader />
      <Container>
        <SectionOne>
          <TriangleFrame src={triangleFrame} layoutId="triangleFrame" />
          <LogoOxeTechHat
            src={oxeTechHat}
            layoutId="oxeTech"
            style={{
              marginLeft: '15vw',
              marginTop: '100vh',
              position: 'absolute'
            }}
          />

          <LogoOxeDin
            src={oxeDinLogo}
            layoutId="oxeDin"
            style={{
              marginLeft: '58%',
              marginTop: '100vh',
              position: 'absolute'
            }}
          />

          <LogoOxeTechWork
            src={oxeTechWorkLogo}
            layoutId="oxeTechWork"
            style={{
              marginLeft: '-40%',
              marginTop: '50%',
              position: 'absolute'
            }}
          />

          <InovationMap
            src={InovationMapIcon}
            layoutId="inovationMap"
            style={{
              marginLeft: '100vw',
              marginTop: '-20vh',
              position: 'absolute'
            }}
          />
          <div style={divStyle}>
            <Link
              to="/"
              style={{
                marginLeft: '30%',
                marginBottom: '38%',
                position: 'absolute'
              }}
            >
              <LogoOxeTechLab src={oxeTechLabLogoON} layoutId="oxeTechLab" />
            </Link>
            <Text
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2, duration: 0.2 }}
            >
              <Typography
                className="typ"
                variant="body1"
                gutterBottom
                sx={{ textAlign: 'justify', fontWeight: '300' }}
              >
                O OxeTech Lab pretende oferecer uma nova alternativa ao mercado
                tecnológico alagoano, para sensibilizar o ecossistema local, por
                meio de uma capacitação técnica e profissional em tecnologia da
                informação e segmentos correlatos, aos cidadãos locais,
                facilitando a integração e sensibilização de empresários,
                alunos, profissionais e professores, por meio da implantação de
                uma rede de polos de educação à distância. O propósito do
                programa é interiorizar e popularizar a ciência, a tecnologia e
                a inovação através de cursos online gratuitos, bem como tem o
                intuito de garantir espaços moderno e organizados, com vistas a
                estabelecer uma maior absorção de conhecimento por parte dos
                alunos que fazem uso do programa.
                <br />{' '}
              </Typography>
            </Text>
          </div>
          {/* <Link className="tool" to="/dash-oxe-din">
            <Tooltip title="OxeDin" placement="top">
              <a>Próximo</a>
            </Tooltip>
          </Link> */}
        </SectionOne>
      </Container>
    </>
  )
}

export default Main
