import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import "animate.css";
import {
  gradienteLaranja,
  gradienteLaranjaInvertido,
} from "../../utils/styledVariables";
export const Container = styled("div")`
  // background: linear-gradient(
  //   180deg,
  //   #741f8a 48.1%,
  //   #340e74 89.08%,
  //   #000063 122.33%

  background: "#FFF";
  overflow: auto;
  position: relative;
  /* width */
  /* ::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f16f;
    border: 1px solid red;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #8f54a0;
    border-radius: 10px;
  } */
`;

export const Introducao = styled(Box)`
  width: 100%;
  height: 83vh;

  background-color: #FFF;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  // padding: 2.5vh 5vw;

  .grow {
    @media (min-width: 700px) {
      flex-grow: 1;
    }
  }
  @media (max-width: 900px) {
    padding: 0;
    justify-content: flex-end;
    flex-direction: column;
  }

  @media (max-width: 380px) {
    padding: 0;
    justify-content: flex-end;
    flex-direction: column-reverse;
  }
  .image-section {
  position: relative;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  overflow: hidden;
  @media (max-width: 1500px){
    width: 40%;
    
  }
  @media (max-width: 440px){
    width: 100%;
    margin-top:1rem;
    
  }

  
}
.links-sociais{
  display: flex;
  alignItems: center;
  justifyContent: center;
  flex-direction: column; 
  gap: 1em;
  @media (max-width: 440px){
    flex-direction:row;
    
  }
}
.image-section img{
  width: 1000px;
  height: auto;
  object-fit: cover;
  @media (max-width: 1500px){
    width: 900px;
    
  }
  @media (max-width: 1200px){
    width: 800px;
    
  }
  @media (max-width: 1000px){
    width: 600px;
    
  }
    @media (max-width: 440px){
    width: 500px;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    gap:1rem
    
  }
}


.background-circle{
  position: absolute;
  width: 590px; /* Largura do círculo */
  height: 590px; /* Altura do círculo */
  background-color: #fdf5e0; /* Cor do círculo */
  border-radius: 50%; /* Faz o círculo */
  z-index: -1; /* Coloca atrás da foto */
  top: 50%; /* Centraliza verticalmente */
  left: 50%; /* Centraliza horizontalmente */
  transform: translate(-50%, -50%); /* Ajusta a posição para o centro */
  @media (max-width: 1500px){
    width: 540px;
    height: 540px;
  }
  @media (max-width: 1300px){
    width: 500px;
    height: 500px;
    
  }
  @media (max-width: 1200px){
    width: 460px;
    height: 460px;
    
  }
    
  @media (max-width: 1000px){
    width: 370px;
    height: 370px;
    
    
  }
 
}
 




.semi-circle {
    position: absolute;
    right: 0%;
    top: 45%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    transform: translate(-50%, -50%);
   
}

.circle {
    width: 80px;
    height: 40px;
    background-color:#e8b545;
    transform: rotate(130deg); 
    border-radius: 40px 40px 0 0;
  @media (max-width: 440px){
    width: 60px;
    height: 30px;
    
  }
    
    
}
.semi-circle2 {
    position: absolute;
    left: 10%;
    top: 45%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    transform: translate(-50%, -50%);
}

.circle2 {
    width: 80px;
    height: 40px;
    background-color: #d43c3c;
    transform: rotate(45deg); 
    border-radius: 40px 40px 0 0;
  @media (max-width: 440px){
    width: 60px;
    height: 30px;
    
  }
    
}
.semi-circle3 {
    position: absolute;
    left: 1%;
    top: 6%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    transform: translate(-50%, -50%);

    @media (max-width: 440px){
    top: 3%;
    
    
  }

}

.circle3 {
    width: 80px;
    height: 40px;
    background-color:#e8b545;
    transform: rotate(90deg); 
    border-radius: 40px 40px 0 0;
    
}


`;

export const Title = styled(Box)`
  width: 35vw;
  font-family: 'Montserrat';
  .titulo {
    color: #df622e;
    
    font-family: 'Montserrat';
    font-weight: bold;
    animation: myAnim 4s ease 0s 1 normal backwards;
    @media (max-width: 800px) {
      text-align: center;
    }
   
    @media (max-width: 1500px) {
      font-size: 3.4rem;
    }
    @keyframes myAnim {
      0% {
        opacity: 0;
        transform: translateY(50px);
      }

      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }
    @media (max-width: 1200px) {
      font-size: 3rem;
    }
    @media (max-width: 1000px) {
      font-size: 2rem;
    }
  }
  .linkCadastro{
    text-decoration: none;
    color: #fff;
    background-color: #df622e;
    padding: 15px 30px;
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
    font-family: 'Montserrat';
    @media (max-width: 1200px) {
      font-size: 15px;
    
    }
  @media (max-width: 1000px) {
      font-size: 13px;
    }
  }
  .welcomeText{
    font-size: 24px;
    font-family: 'Montserrat';
    color: #b53231;
    font-weight: 500;
    @media (max-width: 1200px) {
      font-size: 20px;
    }
    @media (max-width: 1000px) {
      font-size: 17px;
    }
  }
  .welcomeText span{
    font-weight: bold;
  }



  .subtitulo {
    width: 34vw;
    color: #000;
    font-family: 'Montserrat';
    font-size: 1.3rem;
    text-align: justify;
    animation: fadeInUp;
    animation-duration: 3s;
    @media (max-width: 1200px) {
      font-size: 1rem;
    }
    @media (min-width: 1800px) {
      font-size: 1.3rem;
    }
    @media (max-width: 800px) {
    width: 90vw;
    }
    @media (max-width: 1000px) {
      font-size: 0.8rem;
    }
    @media (max-width: 440px) {
      width:100%;
      font-size: 1.2rem;
    }
  }
 
  .subtitulo span{
    color: #df622e;
    font-weight: 700;
  }
  @media (max-width: 440px) {
    text-align: center;
    margin-top:2rem;
    width:80%;
    display:flex;
    flex-direction:column;
    gap:1rem;
  }
`;



export const Programas = styled(Box)`
  width: 80%;
  height: auto;
  background: "#fdf9f0";
  display: flex;
  margin-bottom: 4rem;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
 
`;


// LARANJA -- #df622e
// BRANCO - #fdf9f0;
// AZUL - #255aa7