import * as React from "react";
import { BsArrowRightShort } from "react-icons/bs";


import "./styles.css";
import { NavLink } from "react-router-dom";

export default function SobreNos() {

  return (
    <div className="sobre-nos-container">
      <h3>Encontre cursos e vagas!</h3>
      <h1>Vamos construir juntos o futuro?</h1>
      <NavLink className="linkLogin" to="/login">Conecte-se agora<BsArrowRightShort style={{verticalAlign:'middle'}} size={25}/></NavLink>
      <p style={{fontFamily:'Montserrat', fontSize:'18px', color:'#fdf9f0', margin:'15px'}}>Inicie sua jornada no mercado de inovação em Alagoas.</p>
      
    </div>
    
  );
}

