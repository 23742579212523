import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils';
import { useTheme } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import { Box, Button } from '@mui/material'
import { Tabline, useStyles, Title, ButtonLab } from './styles'
import Tabs from '@mui/material/Tabs'
import { NavLink } from 'react-router-dom'

import 'animate.css'
import { laranjaMain } from '../../utils/styledVariables';
import './styles.css';
import { FaBriefcase, FaDollarSign, FaMapMarkerAlt } from "react-icons/fa";
import { MdOutlineWork } from "react-icons/md";



function TabPanel(props) {
  const { children, value, index, classes, ...other } = props


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }} className={classes}>
          {children}
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

const oxetechLab = value => {
  if (value === 0) {
    return <h2 className="title_programas">Oxetech Lab</h2>
  } else {
    return <h2 className="title_programas">Oxetech Lab</h2>
  }
}
const oxedin = value => {
  if (value === 1) {
    return <h2 className="title_programas">Oxedin</h2>
  } else {
    return <h2 className="title_programas">Oxedin</h2>
  }
}
const oxetechWork = value => {
  if (value === 2) {
    return <h2 className="title_programas">Oxetech Work</h2>
  } else {
    return <h2 className="title_programas">Oxetech Work</h2>
  }
}
export default function FullWidthTabs() {
  const theme = useTheme()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = index => {
    setValue(index)
  }
  const classes = useStyles()
  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

  return (
    <Tabline>
      <AppBar position="static" className={classes.app}>
        <Tabs
          value={value}
          onChange={handleChange}
          classes={{
            indicator: classes.indicator
          }}
        // className={classes.menu}
        // variant="fullWidth"
        // aria-label="full width tabs example"
        >
          <Tab
            label={oxetechLab(value)}
            // {...a11yProps(0)}
            className={value === 0 ? classes.tab1 : classes.taboff}
          ></Tab>
          <Tab
            label={oxedin(value)}
            className={value === 1 ? classes.tab2 : classes.taboff}
          // {...a11yProps(1)} className={classes.tab2}
          />
          <Tab
            label={oxetechWork(value)}
            className={value === 2 ? classes.tab3 : classes.taboff}
          // {...a11yProps(2)} className={classes.tab3}
          />

        </Tabs>
      </AppBar>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        interval={20000}
        animateTransitions={true}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel
          value={value}
          index={0}
          dir={theme.direction}
          classes={classes.oxeLab}
        >
          <Title>
            <Box className="content">
              <div className="search-bar-container">
                {/* Filtros */}
                <div className="filters">
                  <div className="filter">
                    <FaBriefcase className="filter-icon" />
                    <select>
                      <option>Interesse profissional</option>
                      <option>Desenvolvedor Front-end</option>
                      <option>Desenvolvedor Back-end</option>
                      <option>Gestor de Tráfego</option>
                      <option>Designer</option>
                      <option>Marketing</option>
                    </select>
                  </div>

                  <div className="filter">
                    <MdOutlineWork className="filter-icon" />
                    <select>
                      <option>Área de atuação</option>
                      <option>Tecnologia</option>
                      <option>Educação</option>
                      <option>Saúde</option>
                      <option>Economia criativa</option>
                    </select>
                  </div>
                </div>

                {/* Pesquisa */}
                <div className="search-section">
                  <div className="location">
                    <FaMapMarkerAlt className="location-icon" />
                    <select>
                      <option>Selecione o laboratório</option>
                      <option>Maceió</option>
                      <option>Arapiraca</option>
                      <option>Batalha</option>
                      <option>Pão de açucar</option>
                      <option>Murici</option>
                      <option>Penedo</option>
                      <option>Delmiro Gouveia</option>
                      <option>Santana do Ipanema</option>
                      <option>Traipu</option>
                      <option>Arapiraca</option>
                      <option>Benedito Bentes</option>
                    </select>
                  </div>

                  <input
                    type="text"
                    placeholder="Digite o que você procura..."
                    className="search-input"
                    placeholderTextColor="black" 
                  />

                  <NavLink to="/oxetechlab-cursos" className="search-button">Pesquisar</NavLink>

                  <button className="reset-button">
                    🔄
                  </button>
                </div>
              </div>
            </Box>

          </Title>
        </TabPanel>
        <TabPanel
          value={value}
          index={1}
          dir={theme.direction}
          classes={classes.oxeDin}
          style={{ boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.5)' }}
        >
          <Title>
            <Box className="content">
              <div className="search-bar-container">
                {/* Filtros */}
                <div className="filters">
                  <div className="filter">
                    <FaBriefcase className="filter-icon" />
                    <select>
                      <option>Interesse profissional</option>
                      <option>Desenvolvedor Front-end</option>
                      <option>Desenvolvedor Back-end</option>
                      <option>Desenvolvedor Full-stack</option>
                      <option>Gestor de Tráfego</option>
                      <option>Designer</option>
                      <option>Marketing</option>
                      
                    </select>
                  </div>

                  <div className="filter">
                    <FaDollarSign className="filter-icon" />
                    <select>
                      <option>Possibilidade salarial</option>
                      <option>Até R$ 2.000</option>
                      <option>R$ 2.000 - R$ 5.000</option>
                      <option>Acima de R$ 5.000</option>
                    </select>
                  </div>

                  <div className="filter">
                    <MdOutlineWork className="filter-icon" />
                    <select>
                      <option>Área de atuação</option>
                      <option>Tecnologia</option>
                      <option>Educação</option>
                      <option>Saúde</option>
                      <option>Economia criativa</option>
                    </select>
                  </div>
                </div>

                {/* Pesquisa */}
                <div className="search-section">
                  

                  <input
                    type="text"
                    placeholder="Digite o que você procura..."
                    className="search-input"
                  />

                  <NavLink to="/oxedin" className="search-button">Pesquisar</NavLink>

                  <button className="reset-button">
                    🔄
                  </button>
                </div>
              </div>
              
            </Box>
          </Title>

        </TabPanel>
        <TabPanel
          value={value}
          index={2}
          dir={theme.direction}
          classes={classes.oxeWork}
        >
          <Title>
            <Box className="content">
              <div className="search-bar-container">
                {/* Filtros */}
                <div className="filters">
                  <div className="filter">
                    <FaBriefcase className="filter-icon" />
                    <select>
                      <option>Interesse profissional</option>
                      <option>Desenvolvedor Front-end</option>
                      <option>Desenvolvedor Back-end</option>
                      <option>Desenvolvedor Full-stack</option>
                      <option>Gestor de Tráfego</option>
                      <option>Designer</option>
                      <option>Marketing</option>
                    </select>
                  </div>

                  <div className="filter">
                    <FaDollarSign className="filter-icon" />
                    <select>
                      <option>Possibilidade salarial</option>
                      <option>Até R$ 2.000</option>
                      <option>R$ 2.000 - R$ 5.000</option>
                      <option>Acima de R$ 5.000</option>
                    </select>
                  </div>

                  <div className="filter">
                    <MdOutlineWork className="filter-icon" />
                    <select>
                      <option>Área de atuação</option>
                      <option>Tecnologia</option>
                      <option>Educação</option>
                      <option>Saúde</option>
                      <option>Economia criativa</option>
                    </select>
                  </div>
                </div>

                {/* Pesquisa */}
                <div className="search-section">
                  

                  <input
                    type="text"
                    placeholder="Digite o que você procura..."
                    className="search-input"
                  />

                  <NavLink to="/oxetechwork" className="search-button">Pesquisar</NavLink>

                  <button className="reset-button">
                    🔄
                  </button>
                </div>
              </div>
              
            </Box>
          </Title>
        </TabPanel>
      </AutoPlaySwipeableViews>
    </Tabline>
  )
}
