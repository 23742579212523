import Grid from '@mui/material/Grid'
import * as React from 'react'
import { gradienteLaranjaInvertido } from '../../utils/styledVariables'
import { BoxPubicoAlvo, Caixa, Item } from './styles'
import './styles.css';
import fotoLab from '../../assets/oxetechlab_person.png';
import fotoWork from '../../assets/oxetechwork_person.png';
import fotoTrilhas from '../../assets/oxetechtrilhas_person.png';
import LogoWork from '../../assets/logoWork.png';
import LogoLab from '../../assets/LogoLab.png';
import LogoTrilhas from '../../assets/LogoTrilhas.png'
import { BsArrowRightShort } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import { useState } from 'react';
import logoWorkBranca from '../../assets/logoWorkBranco.png';
import logoLabBranca from '../../assets/logoLabBranco.png';
import logoTrilhasBranca from '../../assets/logoTrilhasBranco.png';


export default function SobreProgramas() {

  const [selectedCard, setSelectedCard] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const cards = [
    {
      name: "lab",
      img: fotoLab,
      logo: LogoLab,
      title: "Um lab bem perto de você",
      description: "Oferece cursos gratuitos e presenciais para alagoanos, disponibilizando espaços modernos que incentivam o aprendizado em ciência e tecnologia, promovendo o desenvolvimento em todo o Estado.",
      descriptionModal: " O OxeTech Lab torna a ciência e a tecnologia acessíveis a todos os alagoanos. Com cursos presenciais gratuitos, o programa oferece espaços modernos para a capacitação em tecnologia, incentivando o aprendizado e promovendo o desenvolvimento em todas as regiões do Estado",
      titleModal: "Ciência e tecnologia perto de você",
      logoBranco: logoLabBranca,
      color: "#e7932e",
    },
    {
      name: "work",
      img: fotoWork,
      logo: LogoWork,
      title: "Encontre vagas de trabalho",
      description: "Facilita a entrada de estudantes no mercado de trabalho com qualificação intensiva e bolsas de estudo, conectando capacitação e emprego em parceria com universidades e empresas locais",
      descriptionModal: "O OxeTech Work conecta estudantes e empresas locais com foco na empregabilidade e desenvolvimento.",
      titleModal: "Oportunidades de trabalho e aprendizado",
      logoBranco: logoWorkBranca,
      color: "#255aa7",
    },
    {
      name: "trilhas",
      img: fotoTrilhas,
      logo: LogoTrilhas,
      title: "Aprenda a programar de qualquer lugar.",
      description: "O OxeTrilhas é uma plataforma de capacitação gratuita que oferece cursos em tecnologia, como programação, gestão de projetos e cibersegurança.",
      descriptionModal:"O OxeTrilhas é a porta de entrada para o aprendizado e a capacitação em tecnologia, conectando você às competências do futuro.",
      titleModal: "Capacitação para o mercado de trabalho",
      logoBranco: logoTrilhasBranca,
      color: "#b53231",

    }
  ];

  const handleOpenModal = (card) => {
    setSelectedCard(card);
    setIsModalOpen(true);
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedCard(null);
  }

  return (
    <div className='containerProgramas'>
      <h1 className='title_programas'>Prepare-se. Conecte-se. Transforme-se.</h1>
      <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
        {cards.map((card, index) => (
          <div
            key={index}
            // Adiciona a classe baseada no card.name
            className='cardContainer'
          >
            <div className={`card ${card.name}`}>
              <img
                src={card.img}
                alt={card.title}
                style={{


                }}
              />

            </div>
            <img
              src={card.logo}
              className='cardLogoContainer'
            />
            <div className='textCard'>
              <h3 style={{

                margin: "12px 0 8px",
                color: card.name === "lab"
                  ? "#df622e"
                  : card.name === "work"
                    ? "#255aa7"
                    : "#b53231",
                fontFamily: 'Montserrat',
                fontWeight: 'bold',
                fontSize: '18px'

              }}>
                <BsArrowRightShort style={{ verticalAlign: 'middle' }} />
                {card.title}
              </h3>
              <p style={{ color: "#555", fontFamily: 'Montserrat', fontWeight: '500' }}>{card.description}</p>
              <button
                style={{
                  padding: "8px 16px",
                  backgroundColor: card.name === "lab"
                    ? "#df622e"
                    : card.name === "work"
                      ? "#255aa7"
                      : "#b53231",
                  color: "#fdf9f0",
                  border: "none",
                  borderRadius: "5rem",
                  cursor: "pointer",
                  fontFamily: 'Montserrat',
                  fontWeight: '500'
                }}
                onClick={() => handleOpenModal(card)}
              >
                Descubra
                <BsArrowRightShort style={{ verticalAlign: 'middle' }} size={22} />
              </button>
            </div>
          </div>
        ))}
      </div>
      {/* Modal */}
      
      {isModalOpen && selectedCard && (
        
        <div className="modal"
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1000,
            backgroundColor: selectedCard.name === "lab"
              ? "#df622e"
              : selectedCard.name === "work"
                ? "#255aa7"
                : "#b53231",

            borderRadius: "3rem",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            maxWidth: "70%",
            width: "100%",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row'
          }}>
          <div className='img_modal_person'>
            
            <img
              src={selectedCard.img}
              alt={selectedCard.title}
              style={{
                width: selectedCard.name === 'lab' ? '70rem' : selectedCard.name === 'work' ? '45rem' : '53rem', 
                height: "auto", 
                objectFit: "cover", 
                objectPosition: "center", 

              }}
            />
          </div>
          <div className='modalText'>
            <div style={{width:'100%'}}>
              <img src={selectedCard.logoBranco} />
            </div>
            
            <h2 style={{ color: "#fdf9f0", fontFamily: 'Montserrat', fontWeight: 'bold' }}><BsArrowRightShort style={{ verticalAlign: 'middle' }} size={25} />{selectedCard.titleModal}</h2>
            <p style={{ fontFamily: 'Montserrat', color: "#fdf9f0" }}>{selectedCard.descriptionModal}</p>
            {selectedCard.name === 'trilhas' ? '' : 

            <NavLink className={"linkTurmasModal"} to={selectedCard.name === 'lab' ? '/oxetechlab-cursos' : selectedCard.name === 'work' ? '/oxetechwork' : ""}>
              {selectedCard.name === 'lab' ? 'Consultar Turmas' : selectedCard.name === 'work' ? 'Consultar Vagas' : ""}
             <BsArrowRightShort style={{ verticalAlign: 'middle' }} size={22} />
            </NavLink>
            }
            
            <NavLink className="linkCadastro" to="/select-cadastro">Cadastre-se <BsArrowRightShort style={{ verticalAlign: 'middle' }} size={22} /></NavLink>
          </div>

          <button
            style={{
              position: "absolute", // Posiciona o botão em relação ao modal
              top: "16px", // Distância do topo do modal
              right: "16px", // Distância da borda direita do modal
              padding: "4px 8px", // Reduz o padding para se adequar ao tamanho do "X"
              backgroundColor: "transparent", // Remove o fundo
              color: "#fff", // Cor do "X"
              border: "none", // Remove a borda
              fontSize: "20px", // Aumenta o tamanho do "X"
              fontWeight: "bold", // Deixa o "X" em negrito
              cursor: "pointer", // Mostra que é clicável
            }}
            onClick={handleCloseModal} // Fecha o modal
          >
            &times; {/* Representa o símbolo "X" */}
          </button>

        </div>
      )}

      {/* Overlay para o modal */}
      {isModalOpen && (
        <div
          onClick={handleCloseModal}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 999
          }}
        />
      )}
    </div >
  );
}
